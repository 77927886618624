import App from 'next/app'
import React from 'react'
import axios from 'axios'
import { TinaCMS, TinaProvider } from 'tinacms'
import { GithubClient, TinacmsGithubProvider } from 'react-tinacms-github'
import TagManager from 'react-gtm-module'
import { AppShell } from '../components/AppShell'
import { ReactHelmet } from '../components/ReactHelmet'
import * as Sentry from '@sentry/node'

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.ENV
  })
}

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

if (GTM_ID && typeof document !== 'undefined') {
  TagManager.initialize({
    gtmId: GTM_ID
  })
}

class Root extends App {
  constructor(props) {
    super(props)

    this.cms = new TinaCMS({
      enabled: !!props.pageProps.preview,
      apis: {
        github: new GithubClient({
          proxy: '/api/proxy-github',
          authCallbackRoute: '/api/create-github-access-token',
          clientId: process.env.GITHUB_CLIENT_ID,
          baseRepoFullName: process.env.REPO_FULL_NAME,
          authScope: 'repo',
          baseBranch: process.env.BASE_BRANCH
        })
      },

      sidebar: props.pageProps.preview,
      toolbar: props.pageProps.preview
    })

    this.state = {
      hasCMSAccess: false
    }
  }

  async componentDidMount() {
    const token = localStorage.getItem('oeds-auth-token')

    if (token) {
      try {
        // check if token is valid
        await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/client-accounts`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        this.setState({ hasCMSAccess: true })
      } catch (err) {
        this.setState({ hasCMSAccess: false })
        console.error(err)
        localStorage.removeItem('oeds-auth-token')
      }
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles)
  }

  render() {
    const { Component, pageProps } = this.props
    const { hasCMSAccess } = this.state

    return (
      <>
        <ReactHelmet />
        <AppShell cms={this.cms} hasCMSAccess={hasCMSAccess}>
          <TinaProvider cms={this.cms}>
            <TinacmsGithubProvider onLogin={onLogin} onLogout={onLogout} error={pageProps.error}>
              <Component {...pageProps} cms={this.cms} />
            </TinacmsGithubProvider>
          </TinaProvider>
        </AppShell>
      </>
    )
  }
}

const onLogin = async () => {
  const token = localStorage.getItem('tinacms-github-token') || null
  const headers = new Headers()

  if (token) {
    headers.append('Authorization', 'Bearer ' + token)
  }

  const resp = await fetch(`/api/preview`, { headers })
  const data = await resp.json()

  if (resp.status === 200) window.location.href = window.location.pathname
  else throw new Error(data.message)
}

const onLogout = () =>
  fetch(`/api/reset-preview`).then(() => {
    window.location.reload()
  })

export default Root
