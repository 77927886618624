export default `
  html {
    font-size: 10px; // all rem app values will be calculated base on this value 
  }

  body {
    position: relative;
    background-color: #fff;
    margin: 0;
    font-family: "IBM Plex Sans",  "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .modal-open {
    height: 100vh;
    overflow: hidden;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  body, html, #__next {
    width: 100%;
    height: 100%; 
    box-sizing: border-box;
    font-family: "IBM Plex Sans", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  a {
    text-decoration: none;
    color: #696d90;
  }

  h1 {
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    color: #081377;
    margin: 0 0 2.9rem 0;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 0.8;
    color: #081377;
    margin: 0 0 3rem 0;
  }

  h3 { 
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    color: #081377;
    margin: 1.7rem 0;
  }

/* text under h1 e.g. homepage description in header */

  h4 {
    font-size: 22px;
    font-weight: normal;
    opacity: 0.8;
    line-height: 1.45;
    color: #081377;
  } 

/*  home page-subsection titles */

  h5 {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.5;
    color: #081377;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  /* dark paragraph in tables */

  h6 { 
    width: 192px;
    font-size: 14px;
    font-weight: normal;
    color: #081377;
  }

  /* general text */

  p {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.56;
    color: #696d90;
  }

  .tinyP {
    font-size: 12px;
    font-weight: normal;
    color: #696d90;
  }
  
  li {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.56;
    color: rgb(105, 109, 144);
  }

  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 16px;
      opacity: 0.8;
      color: #696d90;
    }
    p {
      font-size: 14px;
    }
  }

`
