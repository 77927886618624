import React, { Component } from 'react'
import axios from 'axios'
import { validateEmail } from '../helpers/validateEmail'
import { Modal } from '../components/molecules/Modal'
import { ModalContext } from '../containers/MemberModalContext'

class BecomeMemberContainer extends Component {
  state = {
    fullName: '',
    fullNameError: false,
    fullNameHelperText: '',
    email: '',
    emailError: false,
    emailHelperText: '',
    company: '',
    companyError: false,
    companyHelperText: '',
    message: '',
    messageError: false,
    messageHelperText: '',
    showThankYou: false,
    showErrorMessage: false
  }

  onInputChange = (e) => {
    const value = e.target.value

    this.setState({
      [e.target.name]: value,
      [`${e.target.name}Error`]: false,
      [`${e.target.name}HelperText`]: ''
    })
  }

  validateFields = () => {
    const { fullName, company, email, message } = this.state

    this.setState({
      fullNameError: !fullName.length,
      fullNameHelperText: !fullName.length ? 'Please enter your full name' : '',
      emailError: !validateEmail(email),
      emailHelperText: !validateEmail(email) ? 'Please enter a valid email address' : '',
      companyError: !company.length,
      companyHelperText: !company.length ? 'Please enter a company name' : '',
      messageError: !message.length,
      messageHelperText: !message.length ? 'Please enter a message' : ''
    })
    return validateEmail(email) && !!fullName.length && !!company.length && !!message.length
  }

  onSubmit = async () => {
    const { fullName, company, email, message } = this.state
    const allFieldsFilledIn = this.validateFields()
    if (allFieldsFilledIn) {
      try {
        await axios.post(`${process.env.NEXT_PUBLIC_FRONTEND_API_URL}/mailer/oeds-confirmation`, {
          fullName,
          company,
          email,
          message
        })
        this.setState({ showThankYou: true })
      } catch (error) {
        console.log(error)
        this.setState({ showErrorMessage: true })
      }
    }
  }

  render() {
    const {
      fullName,
      fullNameError,
      fullNameHelperText,
      email,
      emailError,
      emailHelperText,
      company,
      companyError,
      companyHelperText,
      message,
      messageError,
      messageHelperText,
      showThankYou,
      showErrorMessage
    } = this.state

    return (
      <ModalContext.Consumer>
        {({ showModal, setShowModal }) => (
          <Modal
            onSubmit={this.onSubmit}
            onInputChange={this.onInputChange}
            fullName={fullName}
            fullNameError={fullNameError}
            fullNameHelperText={fullNameHelperText}
            company={company}
            companyError={companyError}
            companyHelperText={companyHelperText}
            email={email}
            emailError={emailError}
            emailHelperText={emailHelperText}
            message={message}
            messageError={messageError}
            messageHelperText={messageHelperText}
            showModal={showModal}
            setShowModal={setShowModal}
            showThankYou={showThankYou}
            showErrorMessage={showErrorMessage}
          />
        )}
      </ModalContext.Consumer>
    )
  }
}

export default BecomeMemberContainer
