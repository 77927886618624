import React, { useEffect } from 'react'
import styled from 'styled-components'

const StyledMobileNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.heading};
  z-index: ${({ theme }) => theme.zIndex.overlay};
  height: 100%;
  overflow: hidden;

  transition: opacity 0.3s ease-in;

  &.fade {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
    }
  }
`

const NavItems = styled.div`
  padding: 3.5rem 4rem;
`

const NavItem = styled.div`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 500;
  opacity: 0.6;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;

  ${({ active, theme }) =>
    active &&
    `
    opacity: 1;

    &:before {
      width: 30px;
      height: 6px;
      background-color: ${theme.colors.sideNavGreen};
      content: ' ';
      display: block;
      margin-left: -40px;
      margin-right: 10px;
    }
  `}
`
export const MobileNavigation = ({ navigation, route, router, setOpen }) => {
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('modal-open') // prevent scroll in overlay

    // Specify how to clean up after this effect:
    return () => {
      document.getElementsByTagName('body')[0].classList.remove('modal-open') // prevent scroll in overlay
    }
  })

  return (
    <StyledMobileNav>
      <NavItems>
        {navigation.map(({ title, href }) => (
          <NavItem
            onClick={() => {
              router.push(href)
              setOpen(false)
            }}
            active={href === route}
            key={href}
          >
            {title}
          </NavItem>
        ))}
      </NavItems>
    </StyledMobileNav>
  )
}
