import React, { useRef, useEffect, useCallback } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { ArrowForward } from '../atoms/ArrowForward'
import { Input } from '../atoms/Input'

const Background = styled.div`
  background: ${({ theme }) => theme.colors.overlay};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.theme.zIndex.modal};

  transition: opacity 0.2s;

  &.fade {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
    }
  }
`

const ModalWrapper = styled.div`
  width: 80rem;
  box-shadow: 0px 4px 26px -4px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.colors.white};
  position: relative;

  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: none;
  }
`

const ModalContent = styled.div`
  padding: 4rem 16rem 4rem 4rem;
  ${({ theme }) => theme.mediaQueries.maxMobileWidth} {
    padding: 4.3rem 2rem;
  }
`

const Description = styled.p`
  opacity: 0.8;
  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    margin-bottom: 5rem;
  }
`

const CloseModalButton = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  color: ${({ theme }) => theme.colors.darkBlue};
  top: 4rem;
  right: 4rem;
  padding: 0.7rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.lightGray};

  && {
    font-size: 2.4rem;
  }

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    top: 2.5rem;
    right: 2rem;
    padding: 0;
    background-color: none;
  }
`

const StyledInput = styled.div`
  margin-bottom: 2.5rem;
`

const Padding = styled.div`
  padding: 5rem;
`

const Error = styled.h2`
  background-color: ${({ theme }) => theme.colors.danger};
`

const Content = ({
  onSubmit,
  onInputChange,
  fullName,
  fullNameError,
  fullNameHelperText,
  email,
  emailError,
  emailHelperText,
  company,
  companyError,
  companyHelperText,
  message,
  messageError,
  messageHelperText,
  showThankYou,
  showErrorMessage
}) => {
  if (showErrorMessage) {
    return (
      <>
        <Error data-hook="modal-membership-error-title">Something went wrong!</Error>
        <Description>Please try again later</Description>
        <Padding />
      </>
    )
  }
  return showThankYou ? (
    <>
      <h2 data-hook="modal-thank-you-membership-title">Thank You</h2>
      <Description>Thank you for your interest in joining OEDS. We’ll be in touch.</Description>
      <Padding />
    </>
  ) : (
    <>
      <h2 data-hook="modal-apply-membership-title">Apply for Membership</h2>
      <Description>
        Thank you for your interest in OEDS participation. The primary expectation for participation is knowledge of the
        area covered and a willingness to contribute your time and expertise.
      </Description>
      <StyledInput>
        <Input
          value={fullName}
          name="fullName"
          inputId="fullName"
          onChange={onInputChange}
          error={fullNameError}
          label="Full name"
          type="text"
          helperText={fullNameHelperText}
          inputColor="#696d90"
          data-hook="full-name-input"
          inputProps={{
            maxLength: 100
          }}
        />
      </StyledInput>
      <StyledInput>
        <Input
          value={company}
          name="company"
          inputId="company"
          onChange={onInputChange}
          error={companyError}
          label="Company"
          type="text"
          helperText={companyHelperText}
          inputColor="#696d90"
          data-hook="company-input"
          inputProps={{
            maxLength: 100
          }}
        />
      </StyledInput>
      <StyledInput>
        <Input
          value={email}
          name="email"
          inputId="email"
          onChange={onInputChange}
          error={emailError}
          label="Email"
          type="text"
          helperText={emailHelperText}
          inputColor="#696d90"
          data-hook="email-input"
          inputProps={{
            maxLength: 100
          }}
        />
      </StyledInput>
      <StyledInput>
        <Input
          multiline
          rows={4}
          value={message}
          name="message"
          inputId="message"
          onChange={onInputChange}
          error={messageError}
          label="Tell us about your company"
          type="text"
          helperText={messageHelperText}
          inputColor="#696d90"
          data-hook="message-input"
          inputProps={{
            maxLength: 1000
          }}
        />
      </StyledInput>
      <ArrowForward data-hook="button-become-member" onClick={onSubmit} />
    </>
  )
}

export const Modal = ({
  showModal,
  setShowModal,
  onSubmit,
  onInputChange,
  fullName,
  fullNameError,
  fullNameHelperText,
  email,
  emailError,
  emailHelperText,
  company,
  companyError,
  companyHelperText,
  message,
  messageError,
  messageHelperText,
  showThankYou,
  showErrorMessage,
  setShowThankYou
}) => {
  const modalRef = useRef()

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false)
      document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }
  }

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false)
        document.getElementsByTagName('body')[0].classList.remove('modal-open')
      }
    },
    [setShowModal, showModal]
  )

  if (showModal === true) {
    document.getElementsByTagName('body')[0].classList.add('modal-open')
  }

  useEffect(() => {
    document.addEventListener('keydown', keyPress)
    return () => document.removeEventListener('keydown', keyPress)
  }, [keyPress])

  return (
    <CSSTransition in={showModal} classNames={'fade'} timeout={200} unmountOnExit>
      <Background onClick={closeModal} ref={modalRef}>
        <ModalWrapper>
          <CloseModalButton
            data-hook="modal-close-button"
            aria-label="Close modal"
            onClick={() =>
              setShowModal((prev) => !prev, document.getElementsByTagName('body')[0].classList.remove('modal-open'))
            }
          />
          <ModalContent>
            <Content
              onSubmit={onSubmit}
              onInputChange={onInputChange}
              fullName={fullName}
              fullNameError={fullNameError}
              fullNameHelperText={fullNameHelperText}
              company={company}
              companyError={companyError}
              companyHelperText={companyHelperText}
              email={email}
              emailError={emailError}
              emailHelperText={emailHelperText}
              message={message}
              messageError={messageError}
              messageHelperText={messageHelperText}
              showModal={showModal}
              setShowModal={setShowModal}
              showThankYou={showThankYou}
              showErrorMessage={showErrorMessage}
              setShowThankYou={setShowThankYou}
            />
          </ModalContent>
        </ModalWrapper>
      </Background>
    </CSSTransition>
  )
}
