import React from 'react'
import styled from 'styled-components'
import FormHelperText from '@material-ui/core/FormHelperText'
import MaterialInput from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

const StyledFormControl = styled(FormControl)`
  position: relative;
  && {
    &.MuiFormControl-root {
      width: 100%;
    }
  }
`

const SanitizedLabel = ({ inputColor, ...props }) => <InputLabel {...props} />

const StyledInputLabel = styled(SanitizedLabel)`
  && {
    color: ${(props) => props.theme.colors.text};
    font-size: 1.6rem;
    top: -1rem;
    &.MuiInputLabel-shrink {
      font-size: 0;
      top: 0;
    }
    &.Mui-focused {
      color: ${({ theme, inputColor }) => inputColor || theme.colors.darkBlue};
    }
    &.Mui-error {
      color: ${({ theme }) => theme.colors.danger};
    }
    &.Mui-error.MuiInputLabel-shrink {
      color: ${({ theme }) => theme.colors.danger};
    }
  }
`

const SanitizedInput = ({ inputColor, ...props }) => <MaterialInput {...props} />

const StyledInput = styled(SanitizedInput)`
  && {
    margin-top: 0.8rem;
    margin-bottom: 0.2rem;
    font-size: 1.6rem;
    width: 100%;
    &.Mui-disabled {
      color: ${(props) => props.readOnly && props.theme.colors.black};
    }
    &.MuiInput-underline:before {
      border-bottom: 1px solid ${(props) => props.theme.colors.underline};
    }
    &.MuiInput-underline:after {
      border-bottom: 1px solid ${({ theme, inputColor }) => inputColor || theme.colors.darkBlue};
    }
    &.MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid ${({ theme, inputColor }) => inputColor || theme.colors.darkBlue};
    }
    &.Mui-disabled:before {
      border-bottom: none;
    }
    &.MuiInput-underline.Mui-error:after {
      border-bottom-color: ${(props) => props.theme.colors.danger};
    }
  }
`

const StyledFormHelperText = styled(FormHelperText)`
  position: absolute;
  bottom: -12px;
  && {
    &.MuiFormHelperText-root {
      margin-top: 0;
      font-size: 1rem;
    }
    &.Mui-error {
      color: ${(props) => props.theme.colors.danger};
    }
  }
`

export const Input = ({
  value,
  name,
  inputId,
  onChange,
  error,
  label,
  type,
  helperText,
  inputColor = null,
  ...props
}) => (
  <StyledFormControl>
    <StyledInputLabel inputColor={inputColor} error={error} htmlFor={inputId}>
      {label}
    </StyledInputLabel>
    <StyledInput
      {...props}
      name={name}
      value={value}
      onChange={onChange}
      id={inputId}
      aria-describedby={inputId}
      error={error}
      type={type}
      inputColor={inputColor}
    />
    {helperText && (
      <StyledFormHelperText error={error} id={`${inputId}-helper`}>
        {helperText}
      </StyledFormHelperText>
    )}
  </StyledFormControl>
)
