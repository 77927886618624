const colors = {
  white: '#ffffff',
  black: '#000000',
  grey: '#e1e2e9',
  heading: '#081377',
  text: '#696d90',
  danger: '#ef4338',
  sideNavGreen: '#5cbd84',
  darkBlue: '#081377',
  lightGray: '#f8f8f9',
  divider: 'rgb(105 109 144 / 0.1)',
  underline: '#b4b8d6',
  overlay: 'rgba(255, 255, 255, 0.95)'
}

const mediaQueries = {
  minDesktopWidthLarge: '@media only screen and (min-width: 1346px)',
  maxDesktopWidth: '@media only screen and (max-width: 1345px)',
  minDesktopWidth: '@media only screen and (min-width: 1024px)',
  maxTabletWidth: '@media only screen and (max-width: 1023px)',
  minTabletWidth: '@media only screen and (min-width: 768px)',
  maxMobileWidth: '@media only screen and (max-width: 767px)',
  minMobileWidthWide: '@media only screen and (min-width: 410px)',
  minMobileWidthMedium: '@media only screen and (min-width: 384px)',
  minMobileWidth: '@media only screen and (min-width: 361px)',
  maxMobileWidthSmall: '@media only screen and (max-width: 360px)',
  minMobileWidthMediumSmall: '@media only screen and (min-width: 350px)',
  maxRotateMobileHeightLarge: '@media only screen and (max-height: 415px)',
  maxRotateMobileHeight: '@media only screen and (max-height: 375px)'
}

// for iphone X for example so the bottom part is not covered
const safeArea = {
  safeAreaPaddingBottom: 'env(safe-area-inset-bottom)',
  safeAreaPaddingTop: 'env(safe-area-inset-top)'
}

const zIndex = {
  zero: 0,
  minimal: 1,
  overlay: 2,
  burger: 3,
  logo: 4,
  footer: 5,
  modal: 6
}

const fonts = {
  default: `'IBM Plex Sans', sans-serif`,
  mono: `'IBM Plex Mono', monospace`
}

const theme = {
  colors,
  mediaQueries,
  safeArea,
  zIndex,
  fonts
}

export default theme
