import styled from 'styled-components'

export const HideXS = styled.div`
  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    display: none;
  }
`

export const HideMD = styled.div`
  ${({ theme }) => theme.mediaQueries.minDesktopWidth} {
    display: none;
  }
`
