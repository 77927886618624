import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../theme'
import GlobalStyles from '../theme/globalStyles'
import { PageMaxWidth } from './PageMaxWidth'
import { TopNavigation } from './molecules/TopNavigation'
import { Footer } from './Footer'
import { MaxWidthWrapper } from './atoms/MaxWidthWrapper'
import { ModalContextProvider } from '../containers/MemberModalContext'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    padding: 0 2rem;
  }
`

const Container = styled.div`
  display: flex;
  margin-right: 2rem;

  div[class*='ToolbarPlaceholder'] {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    margin-right: 0;
  }
`

const SideNavigationContainer = styled.div`
  width: 20rem;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    width: 0;
  }
`

export const AppShell = ({ children, cms, hasCMSAccess }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <PageMaxWidth cmsEnabled={cms.enabled}>
        <ModalContextProvider>
          <SideNavigationContainer id="side-navigation" />
          <Content>
            <MaxWidthWrapper>
              <TopNavigation cms={cms} hasCMSAccess={hasCMSAccess} />
            </MaxWidthWrapper>
            <Container>{children}</Container>
            <Footer />
          </Content>
        </ModalContextProvider>
      </PageMaxWidth>
    </>
  </ThemeProvider>
)
