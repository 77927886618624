export const validateEmail = (email) => {
  const regEx = /\S+@\S+\.\S+/ // this regex validates any email in form something@something.something
  const validEmail = regEx.test(email)

  if (!email.length || !validEmail) {
    return false
  }

  return true
}
