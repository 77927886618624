import React, { useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { navigation } from '../content/navigation'
import BecomeMemberContainer from '../containers/BecomeMemberContainer'
import { ModalContext } from '../containers/MemberModalContext'

const FooterNavigation = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10rem 2rem 0 0;
  flex-wrap: wrap;
  margin-left: -16rem;
  z-index: ${({ theme }) => theme.zIndex.footer};

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    margin-left: 0;
  }
`

const Member = styled.a`
  cursor: pointer;
  margin: 0 2rem 2rem auto;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text};

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    margin-left: 0;
  }
`

const NavItem = styled.a`
  margin: 0 3rem 2rem 0;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text};
`
const FooterBottom = styled.div`
  display: flex;
`

const Copyright = styled.p`
  font-size: 1.2rem;
  font-weight: normal;
  opacity: 0.5;
  margin: ${({ isProduction }) => (isProduction ? '0.5rem 0 0 0' : '0.5rem 0 0 -16rem')};
  padding: 2rem 0 4rem 0;
  display: inline-block;

  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    margin: 0.5rem 0 0 0;
  }
`

const LegalLink = styled.p`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: normal;
  opacity: 0.5;
  margin: 0.5rem 0 0 3rem;
  padding: 2rem 0 4rem 0;
  cursor: pointer;
`

export const Footer = () => {
  const router = useRouter()
  const year = new Date().getFullYear()
  const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

  if (isProduction) {
    return (
      <FooterBottom>
        <Copyright isProduction={isProduction}>© Copyright {year} Open Employment Data Standards</Copyright>
        <LegalLink data-hook="legal-link">
          <Link href="https://argyle.com/legal">Legal</Link>
        </LegalLink>
      </FooterBottom>
    )
  }

  return (
    <ModalContext.Consumer>
      {({ showModal, setShowModal }) => (
        <>
          <FooterNavigation data-hook="footer">
            {navigation.map(({ title, href }) => (
              <Link href={href} key={href} passHref>
                <NavItem>{title}</NavItem>
              </Link>
            ))}
            <Member data-hook="footer-modal-apply-membership-link" onClick={() => setShowModal(true)}>
              Apply for Membership
            </Member>
          </FooterNavigation>
          <FooterBottom>
            <Copyright>© Copyright {year} Open Employment Data Standards</Copyright>
            <LegalLink data-hook="disclosures-link">
              <Link href="/disclosures">Disclosures</Link>
            </LegalLink>
          </FooterBottom>
          <BecomeMemberContainer showModal={showModal} setShowModal={setShowModal} />
        </>
      )}
    </ModalContext.Consumer>
  )
}
