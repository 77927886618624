import React from 'react'
import styled from 'styled-components'

const StyledPageMaxWidth = styled.div`
  max-width: 150rem;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  display: flex;

  ${({ cmsEnabled }) => cmsEnabled && 'margin-top: 62px;'}
`

export const PageMaxWidth = ({ children, cmsEnabled }) => (
  <StyledPageMaxWidth cmsEnabled={cmsEnabled}>{children}</StyledPageMaxWidth>
)
