import React, { useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { CSSTransition } from 'react-transition-group'
import { navigation } from '../../content/navigation'
import { HideMD, HideXS } from '../atoms/mediaQueries'
import Link from 'next/link'
import { Burger } from '../atoms/Burger'
import { MobileNavigation } from './MobileNavigation'

const StyledTopNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5rem 0 9.5rem 0;
  width: 100%;
`

const NavItem = styled.a`
  margin-left: 4rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.heading};
  cursor: pointer;
  position: relative;
  &:last-of-type {
    margin-right: 2rem;
  }
  ${({ active, theme }) =>
    active &&
    `
    &:after {
      width: 100%;
      height: 4px;
      background-color: ${theme.colors.sideNavGreen};
      content: ' ';
      display: block;
      position: absolute;
      bottom: -5px;
    }
  `}
`
const PaddingBottom = styled.div`
  padding-bottom: 7.6rem;
  ${({ theme }) => theme.mediaQueries.maxTabletWidth} {
    padding-bottom: 10rem;
  }
`
const EditButton = styled(NavItem)`
  color: ${({ theme }) => theme.colors.sideNavGreen};
`
export const TopNavigation = ({ hasCMSAccess, cms }) => {
  const router = useRouter()
  const { route } = router
  const [open, setOpen] = useState(false)

  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    return null
  }

  return (
    <>
      <HideMD>
        <Burger open={open} setOpen={() => setOpen(!open)} />
        <PaddingBottom />
        <CSSTransition in={open} classNames={'fade'} timeout={200} unmountOnExit>
          <MobileNavigation navigation={navigation} route={route} router={router} setOpen={setOpen} />
        </CSSTransition>
      </HideMD>
      <HideXS>
        <StyledTopNavigation>
          {hasCMSAccess && (
            <EditButton onClick={() => cms.toggle()}>{cms.enabled ? 'Exit Edit Mode' : 'Edit This Site'}</EditButton>
          )}
          {navigation.map(({ title, href }) => (
            <Link href={href} key={href} passHref>
              <NavItem active={href === route}>{title}</NavItem>
            </Link>
          ))}
        </StyledTopNavigation>
      </HideXS>
    </>
  )
}
